<template>
    <div>
      <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
      <v-layout wrap px-5 pt-5>
        <v-flex xs12 sm8 md8 text-left>
          <span style="color: #000; font-family: poppinsbold; font-size: 25px"
            >Exam List</span
          >
        </v-flex>
        <v-flex xs12 sm4 md4>
          <v-select
            dense
            :items="items"
            v-model="status"
            label="Status"
            outlined
            clearable
            item-text="name"
            item-value="value"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center pt-5 px-5>
        <v-flex xs12>
          <v-data-table
            :headers="headers"
            :items="user"
            hide-default-footer
            :items-per-page="limit"
            class="elevation-1 .text--black"
            id="virtual-scroll-table"
          >
            <!-- <template v-slot:[`item.create_date`]="{ item }">
              <span v-if="item.create_date">{{
                item.create_date.slice(0, 10)
              }}</span>
            </template> -->
            <template v-slot:[`item.isEssayCompleted`]="{ item }">
              <span v-if="item.isEvaluationCompleted && item.isTrueOrFalseCompleted"
                >{{ item.markAttainedFromEassy }}</span
              >
              <span v-if="item.isEvaluationCompleted==false && item.isTrueOrFalseCompleted"
                ><v-btn color="green" :to="'/evaluation?id=' + item._id" dark><span style="color: white;">Evaluate</span></v-btn></span
              >
            </template>
            <template v-slot:[`item._id`]="{ item }">
            <v-icon v-if="item.isEvaluationCompleted" small class="mr-2" @click="info(item)"> mdi-eye </v-icon>
          </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center pt-2>
        <v-flex xs12>
          <div class="text-center pb-5" v-if="pages > 1">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
              color="green"
              circle
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        msg: null,
        timeout: 5000,
        page: 1,
        currentPage: 1,
        pages: 0,
        limit: 15,
        user: [],
        status: "Pass",
        items: [
        //   { name: "All", value: "" },
          { name: "Passed", value: "Pass" },
          { name: "Failed", value: "Fail" },
        ],
        keyword: "",
        headers: [
          { text: "Student Name", value: "userId.name", width: "130px" },
          { text: "Email", value: "userId.email", width: "80px" },
          { text: "Course Name", value: "examAttendedCourseId.courseName", width: "250px" },
          { text: "MCQ", value: "markAttainedFromMCQ", width: "30px" },
          { text: "True/False", value: "markAttainedFromTrueOrFalse", width: "30px" },
          { text: "Essay", value: "isEssayCompleted", width: "30px" },
        { text: "Actions", value: "_id", width: "50px" },
        ],
      };
    },
    watch: {
      currentPage() {
        this.getData();
      },
      status() {
        this.getData();
      },
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        console.log(status)
        this.appLoading = true;
        axios({
          url: "/admin/exam/list",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            limit: this.limit,
            page: this.currentPage,
            status:this.status
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.user = response.data.data;
            this.pages = Math.ceil(response.data.totalLength / this.limit);
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      info(id) {
      this.$router.push("/answerView?id=" + id._id);
    },
    },
  };
  </script>
  <style>
  .v-data-table-header {
    background-color: #cccac6;
    font-size: 20px;
    color: white;
  }
  
  .v-data-table .v-data-table-header tr th {
    font-size: 15px !important;
    color: black;
    font-family: poppinsmedium;
  }
  
  .v-data-table tr td {
    font-size: 13px !important;
    color: black;
    font-family: poppinsmedium;
  }
  
  #virtual-scroll-table {
    max-width: 1200px;
    overflow: auto;
  }
  </style>